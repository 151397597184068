import { useEffect, useRef } from 'react';

/**
 * usePrev
 * useEffect에서 deps 값 변화에 따른 업데이트 및 리랜더링시 변경 전의 deps 값을 저장하기 위한 훅.
 * useEffect 내에서 값 비교를 통해 수행할 내용을 분기할 수 있다 (아래 샘플 참고).
 *
 *   const prev = usePrev<{ props: Props }>({ props }); // 현재 props 저장
 *   useEffect(() => {
 *     if (prev.props.aaa !== props.aaa) {
 *       console.info('PREV:', prev.props.aaa, 'CURR:', props.aaa);
 *     }
 *   }, [props.aaa]); // props.aaa 값이 업데이트 될 때.
 *
 * @param value {<T | undefined>}
 * @returns {<T | undefined>}
 */
export function usePrev<T = any | undefined>(value: T | undefined): T | undefined {
  const ref = useRef<T | undefined>(); // NO initial value!

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
