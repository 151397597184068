/*
 * SwiperSlider
 * Swiper v9 only (v6|v10 호환되지 않음, 웹팩에서 ESM -> CJS 트랜스파일하여 사용)
 */
import React, { useRef, useState, useMemo, useCallback, useEffect, useImperativeHandle } from 'react';
import classNames from 'classnames';
import { debounce } from 'restriction';
import SwiperCore, { A11y, Autoplay, Controller, Mousewheel, Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { SwiperProps } from 'swiper/react';
import type SwiperClass from 'swiper/types/swiper-class';
import type { SwiperOptions } from 'swiper/types/swiper-options';

interface CustomSwiperProps extends SwiperOptions, SwiperProps {
  loading?: React.ReactNode; // Swiper 로딩 전 인디케이터 (스켈레톤 이미지 등)
  slideTag?: string; // 각 슬라이드마다 지정할 엘리먼트명 (래퍼는 wrapperTag, 컨테이너는 tag)
  slideClassName?: string; // swiper-slide 컴포넌트 스타일 클래스 지정
  slideStyle?: React.CSSProperties; // swiper-slide 컴포넌트 스타일 지정
  className?: string; // swiper wrapper에 스타일 오버라이딩을 위해 props 명시
  style?: React.CSSProperties; // swiper wrapper에 스타일을 주기 위한 용도로만 사용
}

type Props = React.PropsWithChildren<CustomSwiperProps>;

export type { SwiperClass };
export type SwiperRef = SwiperClass | undefined;

const SwiperSlider = React.forwardRef<SwiperRef, Props>((props, ref) => {
  const {
    loading = null,
    slideTag,
    slideClassName,
    slideStyle,
    className,
    style,
    children,
    ...swiperProps // SwiperOptions
  } = props;
  const hasPagination = !!swiperProps.pagination;
  const swiperClass = useRef<SwiperClass>();
  const [isInitialized, setInitialized] = useState<boolean>(false); // NO useRef
  const [isClientSide, setClientSide] = useState<boolean>(false);
  const mixedSlideStyle = useMemo(() => {
    if (props.slidesPerView === 'auto') {
      // slidesPerView 옵션을 "auto"로 지정시 각 슬라이드 너비가 컨텐츠 내용에 맞춰지도록 조정
      return { display: 'flex', flexBasis: 'content', justifyContent: 'center', alignSelf: 'center', ...slideStyle };
    } else {
      return slideStyle;
    }
  }, [props.slidesPerView, slideStyle]);

  // 스와이퍼 로드 완료시 실행되는 콜백에서 스와이퍼 객체를 담는다.
  // 단일 스와이퍼를 사용 할 컴포넌트에서는 useRef를 이용해 스와이퍼 객체를 컨트롤 한다.
  // 다수의 스와이퍼 객체를 다루기 위해서는 controller 옵션을 사용한다.
  // @see https://swiperjs.com/react#controller
  const onSwiper = useCallback(
    (swiper: SwiperClass) => {
      swiperClass.current = swiper;
      swiperProps.onSwiper?.(swiper);
    },
    [swiperProps],
  );

  useImperativeHandle(ref, () => (isInitialized ? swiperClass.current : undefined), [isInitialized]);

  useEffect(() => {
    const { handler: resizeHandler } = debounce((event: UIEvent) => {
      event.preventDefault();
      swiperClass.current?.update();
    });

    window.addEventListener('resize', resizeHandler, false);

    // 클라이언트에서만 동작하므로 마운트 후 랜더링, 그 전까지는 로딩 인디케이터 존재시 표시
    setClientSide(true);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    if (!isInitialized) {
      setInitialized(true);

      // 스와이퍼 코어 모듈 추가
      const sets = new Set([A11y]); // 기본 적용되어야 하는 모듈들
      !!swiperProps.autoplay && sets.add(Autoplay);
      !!swiperProps.controller && sets.add(Controller);
      !!swiperProps.mousewheel && sets.add(Mousewheel);
      !!swiperProps.pagination && sets.add(Pagination);
      !!swiperProps.scrollbar && sets.add(Scrollbar);
      !!swiperProps.navigation && sets.add(Navigation);
      !!props.modules?.length && props.modules.forEach((set) => sets.add(set)); // 별도 추가 모듈이 있으면 등록
      SwiperCore.use(Array.from(sets));
    }
  }, [isInitialized, swiperProps, props.modules, children]);

  return isClientSide ? (
    <Swiper
      {...swiperProps}
      className={classNames(className, { pagination: hasPagination, custom: hasPagination })}
      style={style}
      onSwiper={onSwiper} // Swiper 초기화 완료 콜백 실행
    >
      {React.Children.map(
        children,
        (childItem, childIndex) =>
          // 슬라이드 아이템이 null|undefined 일때 슬라이드가 생성되지 않도록 처리
          !!childItem && (
            <SwiperSlide key={`slide-${childIndex}`} tag={slideTag} className={slideClassName} style={mixedSlideStyle}>
              {childItem}
            </SwiperSlide>
          ),
      )}
    </Swiper>
  ) : (
    <>{loading}</>
  );
});

export default SwiperSlider;
