import { createRef, useMemo, useState, useEffect } from 'react';
import { getUID } from '@mornya/global-libs/dist/Text';

const defaultClickOutsideOption: Required<ClickOutsideOption> = {
  isEnabled: true,
  isEscapeKeyCallbacks: false,
  isTabKeyCallbacks: false,
  ignoredOutsides: [],
  callback() {},
};

/**
 * useClickOutside
 *
 * const [ref, isClickedOutside] = useClickOutside<HTMLDivElement>({
 *   isEscapeKeyCallbacks: true, // ESC 키를 눌렀을 때 ClickOutside 기능 실행 여부
 *   isTabKeyCallbacks: true, // Tab 키를 눌렀을 때 ClickOutside 기능 실행 여부
 *   callback: () => console.info('CLICKED OUTSIDE'),
 * });
 * ...
 * <div ref={ref}> ... </div>
 *
 * @param clickOutsideOption {ClickOutsideOption}
 * @returns {UseClickOutside}
 */
export function useClickOutside<T extends Element>(clickOutsideOption: ClickOutsideOption): UseClickOutside<T> {
  const targetRef = createRef<T>();
  const [targetId, setTargetId] = useState<string>('');
  const option = useMemo<Required<ClickOutsideOption>>(
    () => ({
      ...defaultClickOutsideOption,
      ...clickOutsideOption,
    }),
    [clickOutsideOption],
  );
  const currentTargetId = useMemo<string | null | undefined>(
    () => targetRef.current?.getAttribute('data-clickoutside-id'),
    [targetRef],
  );

  // ID 지정
  useEffect(() => {
    if (!targetId) {
      const nextTargetId = getUID('xxxx-xxxx');
      setTargetId(nextTargetId);
    } else if (targetRef.current && !targetRef.current.getAttribute('data-clickoutside-id')) {
      targetRef.current.setAttribute('data-clickoutside-id', targetId);
    }
  }, [targetId, targetRef, currentTargetId]);

  // 이벤트 선언
  useEffect(() => {
    if (option.isEnabled) {
      const clickHandler = (event: MouseEvent | TouchEvent) => {
        if (targetRef.current?.getAttribute('data-clickoutside-id') === targetId) {
          // 영역 외부를 클릭했는지 여부 체크
          if (!(targetRef.current === event.target || targetRef.current.contains(event.target as Node))) {
            // 영역 외부를 클릭하더라도 콜백을 무시 할 엘리먼트가 있는지 체크
            if (
              option.ignoredOutsides.length > 0 &&
              option.ignoredOutsides.some((el) => !!el && (el === event.target || el.contains(event.target as Node)))
            ) {
              return;
            }
            option.callback();
          }
        }
      };
      const keyUpHandler = (event: KeyboardEvent) => {
        if (
          (option.isEscapeKeyCallbacks && event.code === 'Escape') ||
          (option.isTabKeyCallbacks && event.code === 'Tab')
        ) {
          if (
            targetRef.current &&
            targetRef.current.getAttribute('data-clickoutside-id') === targetId &&
            !targetRef.current.contains(event.target as Node)
          ) {
            // 외부 HTML요소에 포커스 되어있는 상태에서 Tab키를 이용하여
            // HTML요소간 이동이 있을 경우에도 clickOutside 처리
            event.stopPropagation();
            option.callback();
          }
        }
      };

      // Click 이벤트 등록
      if (window.PointerEvent) {
        document.addEventListener('pointerup', clickHandler, false);
      } else {
        document.addEventListener('mouseup', clickHandler, false);
        document.addEventListener('touchend', clickHandler, false);
      }
      // KeyUp 이벤트 등록 (ESC/Tab 키 처리)
      document.addEventListener('keyup', keyUpHandler, false);

      return () => {
        if (window.PointerEvent) {
          document.removeEventListener('pointerup', clickHandler);
        } else {
          document.removeEventListener('mouseup', clickHandler);
          document.removeEventListener('touchend', clickHandler);
        }
        document.removeEventListener('keyup', keyUpHandler);
      };
    }
  }, [option, targetRef, targetId]);

  return [targetRef, true];
}
