import React from 'react';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import Kidsnote from '@/components/kidsnote';
//import PageNotFound from '@/components/pageNotFound';

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/kidsnote/*" element={<Kidsnote />}>
          <Route path=":contentType/:year/:month/:date" element={<Kidsnote />} />
        </Route>
        {/* FALLBACK */}
        {/*<Route path="/*" element={<PageNotFound />} />*/}
        <Route path="/*" element={<Navigate to="/kidsnote" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
