import { restApi } from '@mornya/restful-libs';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type State = {
  contentType: Kidsnote.ContentType;
  today: [string, string, string]; // [yyyy, mm, dd]
  originalLink: string; // original kidsnote content url
  center: string; // "풍림아이원어린이집"
  centerClass: string; // "꽃잎2반"
  title: string;
  content?: string;
  images: string[]; // attached images
  status: string[]; // children status (notes only)
  author: {
    name: string;
    image: string;
    date: string;
  }; // author(writer) info
  comments: Kidsnote.Comment[];
};

type Actions = {
  getDetail(contentType: Kidsnote.ContentType, year: string, month: string, date: string): Promise<void>;
};

const initialState = (): State => ({
  contentType: 'notes',
  today: ['', '', ''],
  originalLink: '',
  center: '',
  centerClass: '',
  title: '',
  content: '',
  images: [],
  status: [],
  author: {
    name: '',
    image: '',
    date: '',
  },
  comments: [],
});

export const useDetailStore = create<State & Actions>()(
  devtools(
    (set) => ({
      // state
      ...initialState(),
      // methods
      async getDetail(contentType: Kidsnote.ContentType, year: string, month: string, date: string) {
        try {
          const response = await restApi({
            baseURL: process.env.REACT_APP_API_URL,
            url: `/kidsnote/${contentType}/${year}/${month}/${date}`,
            cache: true,
            withCredentials: false,
          });
          if (response?.data) {
            if (response.data.payload.kidsnote.length > 0) {
              set(response.data.payload.kidsnote[0]);
            } else {
              set(initialState());
            }
          }
        } catch (e) {
          console.error(e);
        }
      },
    }),
    { enabled: process.env.NODE_ENV !== 'production' },
  ),
);
