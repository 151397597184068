import { restApi } from '@mornya/restful-libs';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type State = {
  days: Kidsnote.Day[];
};

type Actions = {
  getDays(): Promise<void>;
};

const initialState = (): State => ({
  days: [],
});

export const useDaysStore = create<State & Actions>()(
  devtools(
    (set) => ({
      // state
      ...initialState(),
      // methods
      async getDays() {
        try {
          const response = await restApi({
            baseURL: process.env.REACT_APP_API_URL,
            url: '/kidsnotes',
            cache: true,
            withCredentials: false,
          });
          if (response?.data) {
            if (response.data.payload.kidsnotes.length > 0) {
              set({ days: response.data.payload.kidsnotes });
            } else {
              set(initialState());
            }
          }
        } catch (e) {
          console.error(e);
        }
      },
    }),
    { enabled: process.env.NODE_ENV !== 'production' },
  ),
);
