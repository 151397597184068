import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Classes, H3, H4, Card, Icon, Blockquote, Button, ButtonGroup } from '@blueprintjs/core';
import { Elevation } from '@blueprintjs/core/lib/esm/common/elevation';
import { DatePicker3 } from '@blueprintjs/datetime2';
import classNames from 'classnames';
import SwiperSlider, { SwiperRef } from '@/components/application/SwiperSlider';
import { usePrev } from '@/hooks/addon';
import { useClickOutside } from '@/hooks/clickOutside';
import { useDetailStore } from '@/modules/kidsnote/detail';
import { useDaysStore } from '@/modules/kidsnote/days';
import { fillZero } from '@/utils/Text';
import './style.scss';

type OwnProps = {
  contentType: Kidsnote.ContentType;
  year: string;
  month: string;
  date: string;
};

const firstRecord = [2017, 3, 13];
const lastRecord = [2019, 2, 26];

const Kidsnote: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<OwnProps>();
  const daysStore = useDaysStore((state) => state);
  const detailStore = useDetailStore((state) => state);
  const swiperRef = useRef<SwiperRef>();
  const isListLoaded = useRef<boolean>(false);
  const [isOpenPhotoView, setOpenPhotoView] = useState<boolean>(false);
  const [contentType, setContentType] = useState<Kidsnote.ContentType>(params.contentType ?? 'notes');
  const daysInMonth = useMemo<string[]>(
    () =>
      daysStore.days
        .filter(
          (item) => item.contentType === contentType && item.today[0] === params.year && item.today[1] === params.month,
        )
        .map((item) => item.today.join('')),
    [daysStore.days, contentType, params],
  );
  const currentDate = useMemo<Date | null>(
    () =>
      params.year && params.month && params.date
        ? new Date(parseInt(params.year, 10), parseInt(params.month, 10) - 1, parseInt(params.date, 10))
        : null,
    [params],
  );
  const minDate = useMemo(() => new Date(firstRecord[0], firstRecord[1] - 1, firstRecord[2], 0, 0, 0), []); // 최초 레코드
  const maxDate = useMemo(() => new Date(lastRecord[0], lastRecord[1] - 1, lastRecord[2], 0, 0, 0), []); // 최종 레코드
  const prevCurrentDate = usePrev(currentDate);
  const [photoViewRef] = useClickOutside<HTMLDivElement>({
    isEscapeKeyCallbacks: true,
    isTabKeyCallbacks: false,
    callback: () => setOpenPhotoView(false),
  });

  // 전체 일자 목록 불러오기
  const fetchDays = useCallback(() => {
    daysStore.getDays().catch(console.error);
  }, [daysStore]);

  // 해당 일자별 앨범/노트 컨텐츠 불러오기
  const fetchDetail = useCallback(() => {
    if (currentDate) {
      const year = String(currentDate.getFullYear());
      const month = fillZero(String(currentDate.getMonth() + 1), 2);
      const date = fillZero(String(currentDate.getDate()), 2);

      detailStore.getDetail(contentType, year, month, date).catch(console.error);
    }
  }, [detailStore, contentType, currentDate]);

  const routesTo = useCallback(
    (contentType: Kidsnote.ContentType, paramSelectedDate: Date) => {
      const year = String(paramSelectedDate.getFullYear());
      const month = fillZero(String(paramSelectedDate.getMonth() + 1), 2);
      const date = fillZero(String(paramSelectedDate.getDate()), 2);

      setContentType(contentType);
      navigate(`/kidsnote/${contentType}/${year}/${month}/${date}`);
    },
    [navigate],
  );

  const onChangeDate = useCallback(
    (paramSelectedDate: Date | null) => {
      if (paramSelectedDate) {
        routesTo(contentType, paramSelectedDate);
      }
    },
    [routesTo, contentType],
  );

  const onClickView = useCallback(
    (paramContentType: Kidsnote.ContentType) => () => {
      if (currentDate && paramContentType !== contentType) {
        setContentType(contentType);
        routesTo(paramContentType, currentDate);
      }
    },
    [currentDate, contentType, routesTo],
  );

  const onClickPhotoView = useCallback(
    (selectedIndex: number) => () => {
      swiperRef.current?.slideTo(selectedIndex);
      setOpenPhotoView(true);
    },
    [],
  );

  const onClickClosePhotoView = useCallback(() => setOpenPhotoView(false), []);

  useEffect(() => {
    if (!isListLoaded.current) {
      fetchDays();
      isListLoaded.current = true;
    }
  }, [fetchDays]);

  useEffect(() => {
    if (!params.year || !params.month || !params.date) {
      navigate(
        `/kidsnote/${contentType}/${firstRecord[0]}/${fillZero(firstRecord[1] - 1, 2)}/${fillZero(firstRecord[2], 2)}`,
      );
    }
  }, [contentType, params, navigate]);

  useEffect(() => {
    if (currentDate && currentDate !== prevCurrentDate) {
      if (currentDate < minDate) {
        onChangeDate(minDate);
      } else if (currentDate > maxDate) {
        onChangeDate(maxDate);
      } else {
        fetchDetail();
      }
    }
  }, [params, currentDate, prevCurrentDate, minDate, maxDate, onChangeDate, fetchDetail]);

  return (
    <div className="kidsnote">
      <table cellSpacing={0} cellPadding={10} style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td rowSpan={4} className="kidsnote-cal" valign="top">
              <DatePicker3
                value={currentDate ?? undefined}
                minDate={minDate}
                maxDate={maxDate}
                highlightCurrentDay
                reverseMonthAndYearMenus
                dayPickerProps={{
                  modifiers: {
                    exists: (d) =>
                      daysInMonth.includes(
                        `${d.getFullYear()}${fillZero(d.getMonth() + 1, 2)}${fillZero(d.getDate(), 2)}`,
                      ),
                  },
                  modifiersStyles: {
                    exists: { fontWeight: 'bold', color: 'blue' },
                  },
                }}
                className={Classes.ELEVATION_1}
                onChange={onChangeDate}
              />
              <ButtonGroup fill>
                <Button icon="annotation" active={contentType === 'notes'} onClick={onClickView('notes')}>
                  노트
                </Button>
                <Button icon="book" active={contentType === 'album'} onClick={onClickView('album')}>
                  앨범
                </Button>
              </ButtonGroup>
            </td>
            <td className="kidsnote-content" valign="top">
              <Card elevation={Elevation.ONE} className="heading">
                {detailStore.today[0] ? (
                  <>
                    <span className="head-left">
                      <a
                        href={detailStore.originalLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={`키즈노트의 ${detailStore.contentType} 페이지로 이동합니다.`}
                      >
                        {detailStore.contentType === 'notes' ? (
                          <>
                            <Icon icon="annotation" /> <strong>노트</strong>
                          </>
                        ) : (
                          <>
                            <Icon icon="book" /> <strong>앨범</strong>
                          </>
                        )}
                      </a>
                    </span>
                    <span className="head-right">
                      {detailStore.center} / {detailStore.centerClass}
                    </span>
                  </>
                ) : (
                  <div>해당일자에 데이터가 없습니다.</div>
                )}
              </Card>
            </td>
          </tr>
          <tr>
            <td className="kidsnote-content">
              {detailStore.today[0] ? (
                <Card elevation={Elevation.ONE}>
                  <H3 className="subject">{detailStore.title}</H3>
                  <Blockquote className="article">
                    <H4>
                      <Icon icon="citation" />
                    </H4>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `&nbsp;${detailStore.content?.replace(/\n/g, '<br>&nbsp;') ?? '내용이 없습니다.'}`,
                      }}
                    />
                  </Blockquote>
                  <div className="writer">
                    <Icon icon="draw" size={12} /> {detailStore.author.name} / {detailStore.author.date}
                  </div>
                </Card>
              ) : null}
            </td>
          </tr>
          <tr>
            <td className="kidsnote-content">
              {detailStore.today[0] && detailStore.comments.length ? (
                <Card elevation={Elevation.ONE}>
                  {detailStore.comments.map((item, index) => (
                    <Card key={`comment-${index}`} elevation={Elevation.ZERO}>
                      <div>{item.text}</div>
                      <div className="comment-writer">
                        <Icon icon="draw" size={12} /> {item.author} / {item.date}
                      </div>
                    </Card>
                  ))}
                </Card>
              ) : null}
            </td>
          </tr>
          <tr>
            <td className="kidsnote-content">
              {detailStore.today[0] && detailStore.images.length ? (
                <Card
                  elevation={Elevation.ONE}
                  style={{ display: 'inline-flex', flexFlow: 'wrap', alignItems: 'flex-start', width: '100%' }}
                >
                  {detailStore.images.map((item, index) => (
                    <button
                      key={`content-image-${index}`}
                      type="button"
                      title={`${index + 1}번 포토`}
                      aria-label={`${index + 1}번 포토`}
                      onClick={onClickPhotoView(index)}
                    >
                      <img
                        src={`https://seoyeon-bucket.mornya.com/kidsnote/${detailStore.contentType}/${item}`}
                        alt={`이미지#${index}`}
                        className="photo"
                        style={{ maxHeight: '360px', cursor: 'pointer' }}
                      />
                    </button>
                  ))}
                </Card>
              ) : null}
            </td>
          </tr>
        </tbody>
      </table>

      {/* 포토 뷰 */}
      <div ref={photoViewRef} className={classNames('photo-view', { show: isOpenPhotoView })}>
        {/* 포토 스와이프 */}
        <SwiperSlider
          ref={swiperRef}
          spaceBetween={10}
          slidesPerView={1}
          slidesPerGroupAuto
          slideClassName="photo-view-inner"
          style={{ height: '100%' }}
        >
          {detailStore.images.map((item, index) => (
            <img
              key={`photo-view-image-${index}`}
              src={`https://seoyeon-bucket.mornya.com/kidsnote/${detailStore.contentType}/${item.replace(
                '_thumb',
                '',
              )}`}
              alt={`이미지#${index}`}
            />
          ))}
        </SwiperSlider>
        {/* 닫기 버튼 */}
        <button
          type="button"
          title="닫기"
          aria-label="닫기"
          className="btn-photo-view-close"
          onClick={onClickClosePhotoView}
        >
          <img src="https://mornya.github.io/images/web/navigator/close_white.svg" alt="닫기" width={36} height={36} />
        </button>
      </div>
    </div>
  );
};

export default Kidsnote;
