const ZEROS = ['', '0', '00', '000', '0000', '00000', '000000', '0000000', '00000000'];

/**
 * fillZero
 * 입력받은 숫자 앞에 0을 갯수만큼 채워 리턴한다.
 *
 * @param value {string | number}
 * @param lengthToFill {number}
 * @returns {string}
 */
export function fillZero(value: string | number, lengthToFill: number): string {
  if (typeof value === 'string') {
    return value ? `${ZEROS[lengthToFill - value.length] || ''}${value}` : value;
  } else {
    return `${ZEROS[lengthToFill - String(value).length] || ''}${value}`;
  }
}
